import React from "react";
import Layout from "../../components/layout";
import Rotativa2 from "../../images/rotativa2.jpg";
import SRBanner from "../../images/bannerRotativa.jpg";
import SEO from "../../components/seo";

function SR() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Sondagem Rotativa"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${SRBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white m-4 md:ml-8 md:mt-24 uppercase base-title-golden">
          Sondagem Rotativa (SR)
        </h1>
      </section>

      <div className="m-4 my-10 lg:flex">
        <div className="text-justify m-5 md:m-14 lg:w-3/5 xl:mx-20 my-auto">
          <h3>
            A Sondagem Rotativa é utilizada quando na composição do subsolo é
            constatado a presença de material rochoso. Por meio desta
            investigação é possível retirar, recuperar e analisar o tipo de
            rocha, permitindo reconhecer os solos e rochas que compõem as
            camadas do subsolo de um determinado terreno.
          </h3>
          <h3 className="my-12">
            Com base na análise das amostras coletadas em campo, pode-se indicar
            grau de alteração, faturamento, coerência, xistosidade, porcentagem
            de recuperação e o índice de qualidade da rocha, tornando assim a
            caracterização da rocha precisa, o que é de extrema importância para
            o trabalho de Engenharia Civil, tendo em vista que muitos
            empreendimentos são construídos sobre as camadas rochosas.
          </h3>
        </div>
        <img src={Rotativa2} className="m-auto lg:w-2/5 lg:mr-10" />
      </div>
    </Layout>
  );
}

export default SR;
